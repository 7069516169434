import api from "../index";
import urls from "./url";

// 这个东西是unsplash要求加在header里的验证，有兴趣的同学可以去看他的api，此处不再赘述
//容器取author,添加请求头部信息
// const header = {
//     'Authorization': 'Bearer ' + tool.getCookie('printUser')
// }

export default {
  //获取微信配置
  GetWxConfig(params) {
    return api.post(urls.wxConfig, params);
  },
  //经纬度换取地址
  getWxAddres(params) {
    return api.get(urls.wxAddress, params);
  },

  //获取图书信息
  GetBookInfo(params) {
    return api.post(urls.bookInfo, params);
  },

  //获取整书资源包
  GetBookPackge(params) {
    return api.post(urls.bookPackge, params);
  },

  //整书资源下载记录
  DownBookPackge(params) {
    return api.post(urls.bookDown, params);
  },

  //获取目录信息
  GetFolderInfo(params) {
    return api.post(urls.folderInfo, params);
  },

  //目录资源下载记录
  DownFolderPackge(params) {
    return api.post(urls.folderPackgeDown, params);
  },

  //upload-oss
  UploadFile(params) {
    return api.post(urls.fileOss, params);
  },

  //保存意见反馈
  SaveFeedback(params) {
    return api.post(urls.feedback, params);
  },

  //获取资源信息
  GetResInfo(params) {
    return api.post(urls.resInfo, params);
  },
  //资源下载记录
  DownResource(params) {
    return api.post(urls.resDown, params);
  },

  //----------------------------------------------------
  //视频解析首页目录集合
  getvbook(params, header) {
    return api.post(urls.vbook, params, header);
  },

  //视频解析目录展示
  getvlist(params, header) {
    return api.post(urls.vlist, params, header);
  },

  //视频解析视频访问
  vResCount(params, header) {
    return api.post(urls.vrescount, params, header);
  },

  //获取授权认证
  getWxLogin(params) {
    return api.post(urls.wxLogin, params);
  },
  //获取客服企业微信名片
  getAddContactWay(params) {
    return api.post(urls.ContactWay, params);
  },

  //2023.5-----------跟读模块相关接口
  //获取跟读列表
  // getreslist(params) {
  //   return api.post(urls.reslistUrl, params);
  // },
  //获取跟读资源列表
  getFollowList(params) {
    return api.post(urls.followlistUrl, params);
  },
  //获取跟读打分
  getOraEvalute(params) {
    return api.post(urls.oraEvaluteUrl, params);
  },
  //保存用户得分
  saveUserScore(params) {
    return api.post(urls.userScoreUrl, params);
  },
  //获取词汇速记列表
  // getMemoryList(params) {
  //   return api.post(urls.memorylistUrl, params);
  // },
  //登录换取openID
  getopenId(params) {
    return api.post(urls.openUrl, params);
  },

  //2023.11.29
  //获取跟读总码接口
  getrindex(params) {
    return api.post(urls.rindexUrl, params);
  },

  //2024.5.16
  //获取目录信息
  getTeachFolder(params) {
    return api.post(urls.teachFolder, params);
  },
  //发送邮箱
  sendMail(params) {
    return api.post(urls.sendEmail, params);
  },
};
