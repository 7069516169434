<template>
  <div class="home">
    <pdf />
    <div id="demo"> </div>
  </div>
</template>

<script>
import pdf from '@/components/viewpdf.vue';

export default {
  name: 'HomeView',
  components: {
    pdf
  },
  data() {
    return {
      source:1,
      bid:this.$route.query.bid,
    };
  },
  mounted() {

  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
.home{
  width: 100%;
  height: 100%;
}
</style>
