<template>
  <div class="video">
    <video-player
      id="videoplay"
      class="video-player vjs-custom-skin"
      ref="videoPlayer"
      :playsinline="true"
      :options="playerOptions"
      @timeupdate="onPlayerTimeupdate($event)"
      @play="onPlayerPlay($event)"
      @pause="onPlayerPause($event)"
      @ended="onPlayerEnded($event)"
    >
    </video-player>
    <div v-if="cur.nav" class="vv-head-nav">
      <van-image src="http://res.cdn.quyixian.com/video_cover/nav-title.png" />
      <p>{{ cur.nav }}</p>
    </div>
    <div class="vv-head-level" v-if="isTime">
      <div class="vv-head-level-title">
        <p>选题节点</p>
        <p>共{{ cur.level.length }}小节</p>
      </div>
      <div class="vv-head-level-wrap">
        <div
          :class="
            item.state == true ? 'vv-head-level-text cur' : 'vv-head-level-text'
          "
          @click="playerLevels(item.seconds)"
          v-for="(item, index) in cur.level"
          :key="index"
        >
          {{ item.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<!-- @play\="onPlayerPlay($event)"
@pause\="onPlayerPause($event)"
@ended\="onPlayerEnded($event)"
@waiting\="onPlayerWaiting($event)"
@playing\="onPlayerPlaying($event)"
@loadeddata\="onPlayerLoadeddata($event)"
@timeupdate\="onPlayerTimeupdate($event)"
@canplay\="onPlayerCanplay($event)"
@canplaythrough\="onPlayerCanplaythrough($event)"
@statechanged\="playerStateChanged($event)"
@ready\="playerReadied"\> -->

<script>
export default {
  name: "mvideo",
  props: ["cur"],
  data() {
    return {
      isTime: false, //默认没有小节
      time: [], //如果有小节循环拿到秒数组
      currtime: "", //当前播放秒数
      currNumber: 0,
      count: 0,
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: true, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4", //这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            src: "", //url地址
          },
        ],
        poster: "", //你的封面地址
        // width: document.documentElement.clientWidth, //播放器宽度
        notSupportedMessage: "未找到视频资源，请刷新页面", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, //全屏按钮
        },
      },
    };
  },
  watch: {
    //当前播放时间，触发且换小节样式
    currtime: function (newData, oldData) {
      if (this.isTime) {
        this.time.map((item, index) => {
          if (item == newData) {
            this.cur.level.map((s, i) => {
              s.state = false;
            });
            this.cur.level[index].state = true;
            //console.log(this.level)
          }
        });
      }
    },
    "cur.url": function (newData, oldData) {
      this.playerOnload();
    },
  },
  mounted() {
    this.playerOnload();
  },
  methods: {
    onPlayerPlay(player) {
      // console.log(this.count)
      // console.log(this.currNumber)
      // console.log('开始播放')
    },
    onPlayerPause(player) {
      // console.log('暂停播放')
    },
    onPlayerEnded(player) {
      // console.log('播放完毕')
      // if (this.currNumber < this.count) {
      //   let i = this.cur.list[this.currNumber + 1]
      //   let p = {
      //     id: i.rid,
      //     url: i.filePath,
      //     nav: i.fileName,
      //     level: i.levels,
      //     list: this.cur.list
      //   }
      //   this.$emit('btnplay', p)
      // }
    },
    //视频监听改变当前播放时间
    onPlayerTimeupdate(player) {
      let t = parseInt(player.cache_.currentTime);
      if (this.currtime < t) {
        this.currtime = t;
      }
    },
    //每次视频且换加载
    playerOnload() {
      //设置播放视频路径
      if (this.cur.url) {
        this.playerOptions.sources[0].src = this.cur.url;
      }
      //提取小节时间索引
      if (
        this.cur.level != undefined &&
        this.cur.level != "" &&
        this.cur.level.length > 0
      ) {
        this.cur.level.map((item) => {
          this.time.push(item.seconds);
        });
        this.isTime = true;
      }
      if (
        this.cur.list != undefined &&
        this.cur.list != "" &&
        this.cur.list.length > 0
      ) {
        this.count = this.cur.list.length;
        this.cur.list.map((item, index) => {
          if (this.cur.id == item.rid) {
            this.currNumber = index + 1;
          }
        });
      }
    },
    //点击小节播放
    playerLevels(seconds) {
      let video = document.getElementsByTagName("video");
      video[0].play();
      video[0].currentTime = seconds;
      this.currtime = seconds;
    },
  },
};
</script>
<style></style>
