<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
//自动设置页面font-size
;(function (win, designPageWidth, baseFontSize) {
  var doc = window.document

  if (!doc || !doc.addEventListener) return

  var reset = function () {
    var w = Math.min(win.screen.width, win.innerWidth),
      fs = baseFontSize

    if (w < designPageWidth) {
      fs = (w / designPageWidth) * baseFontSize
    }

    doc.documentElement.style.fontSize = fs + 'px'
  }

  win.addEventListener('orientationchange' in win ? 'orientationchange' : 'resize', reset, false)

  doc.addEventListener('DOMContentLoaded', reset, false)
})(window, 750, 100)
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  height: 100%;
}
html,
body {
  height: 100%;
}
html {
  font-size: 100px;
  line-height: 2;
}
body {
  font-size: 0.24rem;
}
em {
  font-style: normal;
}
* {
  padding: 0;
  margin: 0;
}
@import 'assets/css/global.scss';
@import 'assets/css/video.scss';
</style>
