const baseUrl = "https://res.api.quyixian.com/api"; //正式环境
//const baseUrl = "https://smallres.api.53online.cn/api"; //测试环境

//sconst followBase = "http://210.13.51.100:8032/api";
const followBase = "https://res.api.quyixian.com/api"; //正式地址
//const followBase = "http://small.api.53online.cn/api"; //测试地址

export default {
  //获取微信配置
  wxConfig: baseUrl + "/Wechat/GetWxConfig",

  //获取地理位置
  wxAddress: baseUrl + "/WxAddress/GetAddress",

  //图书信息
  bookInfo: baseUrl + "/Book/GetBookInfo",

  //获取整书资源
  bookPackge: baseUrl + "/Book/GetBookPackge",

  //下载整书资源记录
  bookDown: baseUrl + "/Book/DownBookPackge",

  //目录信息
  folderInfo: baseUrl + "/Folder/GetFolderInfo",

  //下载目录资源记录
  folderPackgeDown: baseUrl + "/Folder/DownFolderPackge",

  //oss
  fileOss: baseUrl + "/File/UploadFileOSS",

  //保存意见反馈
  feedback: baseUrl + "/BookFeedback/SaveFeedback",

  //资源信息
  resInfo: baseUrl + "/Resource/GetResourceInfo",

  //下载资源记录
  resDown: baseUrl + "/Resource/DownResource",

  //视频解析-book
  vbook: baseUrl + "/BookVideo/GetBookInfo",

  //视频解析-lists
  vlist: baseUrl + "/FolderVideo/GetFolderInfo",

  //视频解析-记录资源访问次数
  vrescount: baseUrl + "/ResourceVideo/VisitResource",

  //静默授权企业微信
  wxLogin: baseUrl + "/Wechat/WxLogin",

  //生成客服企业微信名片
  ContactWay: baseUrl + "/Wechat/GetAddContactWay",

  //2023.5-----------跟读模块相关接口
  //http://210.13.51.100:8032/api/FollowRead/OralEvalute
  //获取跟读资源列表
  //reslistUrl: followBase + "/FollowRead/GetResList",
  //获取跟读列表
  followlistUrl: followBase + "/FollowRead/GetFollowList",
  //跟读打分
  oraEvaluteUrl: followBase + "/FollowRead/OralEvalute",
  //用户得分
  userScoreUrl: followBase + "/FollowRead/SaveUserScore",
  //获取速记列表
  //memorylistUrl: followBase + "/FollowRead/GetWordMemoryList",
  //获取OPENID
  openUrl: followBase + "/Wechat/GetOpenId",

  //2023.11.29
  //跟读总码页面接口
  rindexUrl: followBase + "/Folder/GetFolderResList",

  //2024.5.16
  //获取目录信息
  teachFolder: followBase + "/Folder/GetTeachFolder",
  //发送邮箱
  sendEmail: followBase + "/Folder/SendMail",
};
