import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    iCdn:'https://i.cdn.quyixian.com/',
    ResCdn:'https://res.cdn.quyixian.com/',
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
